<template>
    <ContentWrapper>
        <breadcrumb name="Storage"/>
        <div class="container animated-fast fadeInUp" v-if="harvests">

            <div class="card card-default">
                <div class="card-header bg-gray-lighter text-bold">{{$t('growing.view.harvests.log')}}</div>
                <div class="card-body">
                    <b-row class="grow-row" v-for="(item, index) in harvests" :key="index">
                        <b-col sm="2" class="grow-boxed">{{item.transaction.label}}</b-col>
                        <b-col sm="9" class="grow-caption">{{$t('growing.view.harvests.amount.label')}} {{Number(item.transaction.amount)}}g
                            {{item.grow.strain_name}} {{$t('growing.view.harvests.amount.from')}}  {{item.plants_amount}} {{$t('growing.view.harvests.amount.plants')}}
                        </b-col>
                        <b-col sm="1">
                            <b-btn variant="primary"
                                   :to="{name: 'Growing:HarvestDistribution', params: {harvest_label: label}}">
                                <i class="fad fa-eye"></i>
                            </b-btn>
                        </b-col>
                    </b-row>
                </div>
            </div>

        </div>
    </ContentWrapper>
</template>
<style>

</style>
<script>

    export default {
        components: {},
        data() {
            return {
                harvests: [],
                loading: true
            }
        },
        mounted() {

            this.$api.get('growing/harvests').then(response => {
                this.harvests = response.data;
                this.loading = false;
            });
        },
        methods: {

        }
    }
</script>
